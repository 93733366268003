import axios from 'axios'
import router from '../router'
import { getToken,removeToken } from './auth'
import { Message } from "element-ui";

const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    token: getToken()
  },
  // 超时
  timeout: 10000
})

// 请求拦截
service.interceptors.request.use(config => {
  // console.log(getToken());
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
}, err => {
  return Primise.reject(err)
  // console.log(err);
});
// // 响应拦截
service.interceptors.response.use(res => {
  if (res.data.code == 20000 || res.data.code == 20001 || res.data.code == 20002 || res.data.code == 20003 || res.data.code == 20004) {
    Message('Errar: 登录状态已过期，请重新登录');
    router.replace({
      path: '/login' // 到登录页重新获取token
    });
    removeToken();
  }
  return res
}, err => {
  console.log(err);
})

export default service

// export function request(config) {
//   const service = axios.create({
//     baseURL: process.env.VUE_APP_BASE_API,
//     timeout: 10000,
//     headers: {
//       token: sessionStorage.getItem("token")
//     },
//   })
//   // 请求拦截
//   service.interceptors.request.use(config => {
//     return config
//   }, err => {
//     return Primise.reject(err)
//     // console.log(err);
//   });
//   // // 响应拦截
//   service.interceptors.response.use(res => {
//     if (res.data.code == 20000 || res.data.code == 20001 || res.data.code == 20002 || res.data.code == 20003 || res.data.code == 20004) {
//       router.replace({
//         path: '/login' // 到登录页重新获取token
//       });
//       sessionStorage.removeItem('token');
//     }
//     return res
//   }, err => {
//     console.log(err);
//   })
//   // return service(config)
// }




