import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'video.js/dist/video-js.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'

Vue.config.productionTip = false

Vue.use(ElementUI);

// router.beforeEach((to, from, next) => {
//   if (to.path === '/login') {
//     next()
//   } else {
//     if (!getToken()) {
//       ElementUI.Message.error('Errar: 无效的会话，或者会话已过期，请重新登录');
//       next({
//         path: '/login'
//       }); // 重定向到登录页面
//     } else {
//       // if (store.getters.roles.length === 0) {
//       //   store.dispatch('user/GetInfo').then(() => {
//       //     next()
//       //   })
//       // } else {
//       //   next(); // 继续导航
//       // }
//       next()
//     }
//   }
// });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')