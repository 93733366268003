import { login,getInfo } from '@/api/login'
import { Message } from "element-ui";
import { setToken,getToken } from "@/utils/auth"

export default {
  namespaced: true,  //开启namespace:true，该模块就成为命名空间模块了
  state: {
    token: getToken(),
    roles: [],
    name: '',
    avatar: '',
    mapZoom: [],
  },
  mutations: { // 修改
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_MAPZOOM: (state, mapZoom) => {
      state.mapZoom = mapZoom
    },
  },
  actions: {
    Login({ commit }, userInfo) {
      const { username, password } = userInfo
      return new Promise((resolve, reject) => {
        login({ username: username.trim(), password: password }).then(res => {
          if (res.data.code == 10000) {
            setToken(res.data.data.token)
            commit('SET_TOKEN', res.data.data.token)
            resolve(res)
          } else {
            Message(res.data.message);
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          if (res.data.code == 10000) {
            const user = res.data.queryData;
            // const avatar = (user.avatar == '' || user.avatar == null) ? require('@/assets/image/avatar.png') : user.avatar
            const avatar = '';
            if (res.data.queryData.roles && res.data.queryData.roles.length > 0) {
              console.log(res.data.queryData.roles);
              commit('SET_ROLES' , res.data.queryData.roles)
            } else {
              commit('SET_ROLES' , ["ROLES_DEFAULT"])
            }
            commit('SET_NAME' , user.nickName)
            commit('SET_AVATAR' , avatar)
            commit('SET_MAPZOOM' , user.mapZoom)
            resolve(res)
          } else {
            Message(res.data.message);
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
  }
}