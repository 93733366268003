import Vue from 'vue'
import VueRouter from 'vue-router'
import smartRouter from './smartRouter.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    redirect: '/index',
  },
  ...smartRouter,
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
