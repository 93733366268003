import request from '@/utils/request.js'

// 登录方法
export function login(data) {
  return request({
    url: 'user/login',
    method: 'post',
    headers: {
      isToken: false
    },
    data,
  })
}
// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/custom/user/current/info',
    method: 'post',
  })
}